import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export function Projects(): JSX.Element {
  return (
    <Box justifyContent="center" textAlign="center">
      <Typography variant="h1">WIP</Typography>
    </Box>
  );
}
